import React from "react";
import { Layout } from "../layouts";

const NewsPage = (props) => (
  <Layout location={props.location}>
    <div className="content-wrap">
      <h1 className="content-h2">Aktuelles</h1>

      <p>
        Die Praxis ist vom 23.12.24 bis zum 03.01.25 geschlossen
        <br />
        <br />
        Vertretung in dringenden Fällen:
        <br />
        Kai Brandau, Moltkestr.81, 76185 Karlsruhe, Tel. 0721/848946
        <br />
        <br />
      </p>
      <br />
    </div>
  </Layout>
);

export default NewsPage;
